import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/SEO';

import Section from '../components/Section';
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';

const ServicesPage = ({ data, location }) => {
  const { fields } = data.allSitePage.edges[0].node.context;

  return (
    <Layout pageId={fields.pageId} location={location} footer={true}>
      <Seo title={`${fields.pageTitle} Development`} />

      <Section id="hero">
        <Container className="flex-col pb-0 lg:pb-0 xl:pb-0">
          <span className="flex-1 flex items-center justify-center w-full mt-20">
            <div className="flex-1 flex flex-row justify-between items-center">
              <h1 className="ff-primary fs-55 fs-lg-58 fw-800 m-0">
                <span className="text-primary">{fields.pageTitle}</span>{' '}
                Development
              </h1>
              <span className="hidden lg:block">
                <img src={fields.pageIcon} alt="logo" />
              </span>
            </div>
          </span>
        </Container>
      </Section>

      <Section id="what-we-do">
        <Container className="flex-col pt-12 lg:pt-12 xl:pt-12">
          <div
            className="flex-1 flex flex-col items-start justify-start w-full mt-2 border-t pt-24 lg:pt-24 xl:pt-24"
            style={{ borderColor: '#393939' }}
          >
            <SectionHeading style={{ maxWidth: 948 }}>
              <div className="text-white fs-16 mb-3">What we do</div>
              <div className="mt-10">{fields.heroSection.title}</div>
            </SectionHeading>
          </div>
          <div>
            {fields.heroSection.para.map((p, index) => (
              <p
                key={index}
                className="mt-12 fs-14 text-gray-2"
                style={{ maxWidth: 680 }}
              >
                {p.text}
              </p>
            ))}
          </div>

          <div className="flex flex-row mt-12">
            {fields.heroSection.stats.map(({ count, title }, index) => (
              <div key={index} className="flex flex-col" style={{ width: 170 }}>
                <span className="fs-36 lg:fs-48 text-white fw-800">
                  {count}
                </span>
                <span className="fs-12 fs-sm-16 text-white">{title}</span>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section id="our-services" backgroundColor="#FFF">
        <Container className="flex-col">
          <div className="flex-1 flex flex-col items-start justify-start w-full mt-2">
            <SectionHeading theme="dark" className="flex flex-col">
              <h2 style={{ maxWidth: 837 }}>{fields.ourServices.title}</h2>
            </SectionHeading>
          </div>

          <div>
            {fields.ourServices.para.map((p, index) => (
              <p
                key={index}
                className="mt-12 fs-14 text-gray"
                style={{ maxWidth: 930 }}
              >
                {p.text}
              </p>
            ))}
          </div>

          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-y-10 mt-16"
            style={{ maxWidth: 1000 }}
          >
            {fields.ourServices.services.map(({ title }, index) => (
              <span key={index} className="fs-24 fw-700 text-black">
                {title}
              </span>
            ))}
          </div>
        </Container>
      </Section>

      <Section id="contact-banner" backgroundColor="#FFF">
        <Container>
          <div className="banner">
            <div className="cta">
              <div className="title fs-36 lg:fs-48 fw-400 ff-primary">
                Let&apos;s Discuss your{' '}
                <span className="highlight-title fw-700">Project</span>
              </div>
              <Link
                className="btn border border-white fs-18 lg:fs-24 mt-16 lg:mt-0 fw-400 text-center block"
                to="/contact-us"
              >
                Contact now
              </Link>
            </div>
          </div>
        </Container>
      </Section>

      <Section id="why-choose-us" backgroundColor="#FFF">
        <Container className="flex-col">
          <div className="flex-1 flex flex-col items-start justify-start w-full mt-2">
            <SectionHeading theme="dark" className="flex flex-col">
              <h2 style={{ maxWidth: 837 }}>{fields.whyChooseUs.title}</h2>
            </SectionHeading>
          </div>

          <div>
            {fields.ourServices.para.map((p, index) => (
              <p
                key={index}
                className="mt-12 fs-16 text-gray"
                style={{ maxWidth: 930 }}
              >
                {p.text}
              </p>
            ))}
          </div>

          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-x-40 gap-y-9 mt-16"
            style={{ maxWidth: 930 }}
          >
            {fields.whyChooseUs.ourDevelopmentProccess.map(
              ({ title, description }, index) => (
                <div key={index} className="flex flex-col">
                  <div className="fs-24 fw-800 text-black">{title}</div>
                  <p className="mt-6 fs-16 fw-400 text-gray">{description}</p>
                </div>
              ),
            )}
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default ServicesPage;

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            slug
            fields {
              pageTitle
              pageId
              pageIcon
              heroSection {
                title
                para {
                  text
                }
                stats {
                  count
                  title
                }
              }
              ourServices {
                title
                para {
                  text
                }
                services {
                  title
                }
              }
              whyChooseUs {
                title
                para {
                  text
                }
                ourDevelopmentProccess {
                  title
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;
